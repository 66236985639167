<template>
  <div>
    <DayTemplate
      :dateIn="new Date(new Date().setDate(new Date().getDate() - 1))"
      title="Вчера"
      pathRightSide="/today"
      pathHidden="/today"
    />
    <!-- <div>
      <img
        ref="whale"
        src="../assets/whale4.png"
        alt=""
        v-on:load="whaleOn()"
        class="whalehidden"
      />
    </div> -->
  </div>
</template>

<script>
import DayTemplate from "@/components/DayTemplate.vue";

export default {
  components: { DayTemplate },
  data() {
    return {};
  },

  methods: {
    whaleOn() {
      // if (Math.random() * 4 > 3)
      //   setTimeout(() => (this.$refs.whale.classList = "whalemove"), 1000);
    },
  },
};
</script>
